import { TShippableProductInCart } from "src/domains/cart/cart-types"
import {
  TCurrencyCode,
  TFetchProductsParams,
  TShippingDestination,
} from "src/domains/storefront/storefront-types"

export const queryKeys = {
  all() {
    return ["storefront"] as const
  },
  products() {
    return [...this.all(), "products"] as const
  },
  productList(params?: TFetchProductsParams, userId?: string) {
    if (!params && !userId) {
      return [...this.products()] as const
    }

    if (params && !userId) {
      return [...this.products(), params] as const
    }

    if (!params && userId) {
      return [...this.products(), userId] as const
    }

    return [...this.products(), params, userId] as const
  },
  shippingDestinations() {
    return [...this.all(), "shippingDestinations"] as const
  },
  shippingMethods(
    shippableProducts: TShippableProductInCart[] | undefined,
    countryCode: TShippingDestination["country_code"] | undefined,
    currencyCode: TCurrencyCode | undefined
  ) {
    return [
      ...this.all(),
      "shippingMethods",
      shippableProducts,
      countryCode,
      currencyCode,
    ] as const
  },
  geoLocation() {
    return [...this.all(), "geoLocation"] as const
  },
} as const
