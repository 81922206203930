import {
  TBillingAddress,
  TJwtData,
  TMaybeAuthorization,
} from "src/domains/auth/auth-types"
import { getQueryParam } from "src/domains/http/query-params"
import { TShippingAddressBody } from "src/domains/storefront/storefront-types"

const EU_COUNTRIES = [
  { name: "Austria", code: "AT" },
  { name: "Belgium", code: "BE" },
  { name: "Bulgaria", code: "BG" },
  { name: "Croatia", code: "HR" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Estonia", code: "EE" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "Greece", code: "GR" },
  { name: "Hungary", code: "HU" },
  { name: "Ireland", code: "IE" },
  { name: "Italy", code: "IT" },
  { name: "Latvia", code: "LV" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Malta", code: "MT" },
  { name: "Netherlands", code: "NL" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Romania", code: "RO" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Spain", code: "ES" },
  { name: "Sweden", code: "SE" },
]

export function formatShippingAddressToBillingAddress(
  email: string,
  shippingAddress: TShippingAddressBody
): TBillingAddress {
  const billingAddress = {
    email,
    first_name: shippingAddress.first_name,
    last_name: shippingAddress.last_name,
    company: shippingAddress.company,
    phone: shippingAddress.phone,
    line1: shippingAddress.line1,
    line2: shippingAddress.line2,
    city: shippingAddress.city,
    zip: shippingAddress.zip,
    country: shippingAddress.country,
    ...getProvince(shippingAddress.country, shippingAddress.state_code),
  }

  return billingAddress
}

export function isInEu(countryCode: string) {
  return !!EU_COUNTRIES.find((c) => c.code === countryCode.toUpperCase())
}

export function isCountryVatExempt(countryCode: string) {
  if (countryCode.toUpperCase() === "SE") {
    return false
  }

  return isInEu(countryCode)
}

export function getProvince(countryCode: string, stateCode?: string) {
  if (["US", "CA", "IN"].includes(countryCode)) {
    return { state_code: stateCode }
  }

  if (stateCode) {
    return { state: stateCode, state_code: stateCode }
  }

  return {}
}

export function getJwtFromAccessToken(accessToken: string) {
  const [_, data = ""] = accessToken.split(".")

  const jwtBin = window.atob(data)
  const jwtParsed: TJwtData = JSON.parse(jwtBin)
  return jwtParsed
}

export function getAuthFromQueryparam(): TMaybeAuthorization {
  const urlAccessToken = getQueryParam({ key: "access_token", type: "string" })

  if (!urlAccessToken) return null

  const jwtData = getJwtFromAccessToken(urlAccessToken)

  return {
    access_token: urlAccessToken,
    refresh_token: "n/a",
    expires_in: 3600, // placeholder
    user_id: jwtData.userId,
    token_type: "Bearer",
  }
}
