import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios"

import {
  getStoredRefreshToken,
  setStoredAuthorization,
} from "src/domains/auth/auth-storage"

// TODO: Move this to http domain

const minutApiHttpClient: AxiosInstance = axios.create({
  baseURL: process.env.GATSBY_MINUT_API_URL,
  timeout: 60 * 1000, // 1 minute
})

export function setApiClientAccessToken(accessToken: string): void {
  minutApiHttpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`
}

export function deleteApiClientAccessToken(): void {
  delete minutApiHttpClient.defaults.headers.common["Authorization"]
}

// Used by token refresh interceptor
let isFetchingAccessToken = false

// List of waiting requests that will retry after the token refresh completes
let subscribers: ((accessToken: string) => void)[] = []

async function refreshTokenAndReattemptRequest(
  error: AxiosError,
  onFailedRefresh?: () => void
): Promise<AxiosResponse> {
  const { response: errorResponse } = error

  const refreshToken = getStoredRefreshToken()

  try {
    // If we can't refresh, pass the error on.
    if (!refreshToken) {
      throw Error("No refresh token found")
    }

    /* Token refresh procedure. Creates a new Promise that will retry the
    request, clone the request configuration from the failed request in the
    error object. */
    const retryOriginalRequest = new Promise<AxiosResponse>((resolve) => {
      /* Add the request to the retry queue since there another request that
      already attempt to refresh the token */
      addSubscriber((accessToken) => {
        if (!errorResponse) {
          console.error("No error response found")
          return
        }
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`
        resolve(minutApiHttpClient(errorResponse.config))
      })
    })

    if (!isFetchingAccessToken) {
      isFetchingAccessToken = true

      /**
       * Get refresh token. Needs to use the default axios client, since we
       * otherwise would be in a infinite retry loop, if the refresh endpoint
       * returns a 401 Unauthorized with the provided refresh token.
       */
      const response = await axios.post(
        `${process.env.GATSBY_MINUT_API_URL}/v8/oauth/token`,
        {
          client_id: process.env.GATSBY_MINUT_CLIENT_ID,
          client_secret: process.env.GATSBY_MINUT_CLIENT_SECRET,
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        }
      )

      if (!response.data) {
        throw Error("No response data found")
      }

      const { data } = response

      const newAccessToken = data.access_token

      setStoredAuthorization(data)
      setApiClientAccessToken(newAccessToken)

      isFetchingAccessToken = false
      onAccessTokenFetched(newAccessToken)
    }
    return retryOriginalRequest
  } catch (err) {
    if (onFailedRefresh) {
      onFailedRefresh()
    }

    return Promise.reject(err)
  }
}

function onAccessTokenFetched(accessToken: string): void {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(accessToken))
  subscribers = []
}

function addSubscriber(callback: (accessToken: string) => void): void {
  subscribers.push(callback)
}

export function setTokenExpiredInterceptor({
  onFailedRefresh,
}: {
  onFailedRefresh?: () => void
}): void {
  minutApiHttpClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const errorResponse = error.response

      if (isTokenExpiredError(errorResponse)) {
        return refreshTokenAndReattemptRequest(error, onFailedRefresh)
      }

      // If the error is due to other reasons, just throw it back to axios
      return Promise.reject(error)
    }
  )

  function isTokenExpiredError(errorResponse?: AxiosResponse): boolean {
    return errorResponse?.status === 401
  }
}

export default minutApiHttpClient
