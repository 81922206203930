import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query"
import axios, { AxiosError, AxiosResponse } from "axios"

import { TShippableProductInCart } from "src/domains/cart/cart-types"
import { API_DEFAULT } from "src/domains/http/http-utils"
import minutApiHttpClient from "src/domains/http/minutApiHttpClient"
import { queryKeys } from "src/domains/storefront/storefront-query-cache"
import {
  TCurrencyCode,
  TFetchGeolocationResponse,
  TFetchProductsParams,
  TFetchProductsResponse,
  TFetchShippingDestinationsResponse,
  TFetchShippingMethodsRequestBody,
  TFetchShippingMethodsResponse,
  TGeolocation,
  TShippingDestination,
} from "src/domains/storefront/storefront-types"

export function useFetchProducts(args?: {
  userId?: string
  params?: TFetchProductsParams
  options?: Omit<
    UseQueryOptions<
      TFetchProductsResponse,
      AxiosError,
      TFetchProductsResponse,
      ReturnType<typeof queryKeys.productList>
    >,
    "queryKey" | "queryFn"
  >
}) {
  async function fetchProducts() {
    if (!args?.userId) {
      const response = await minutApiHttpClient.get(
        `${API_DEFAULT}/ecommerce/products`,
        { params: args?.params }
      )
      return response.data
    }

    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/users/${args.userId}/products`,
      { params: args?.params }
    )
    return response.data
  }

  return useQuery({
    queryKey: queryKeys.productList(args?.params, args?.userId),
    queryFn: fetchProducts,
    placeholderData: keepPreviousData,
    ...args?.options,
  })
}

export function useFetchShippingDestinations(
  options?: UseQueryOptions<
    TFetchShippingDestinationsResponse,
    AxiosError,
    TFetchShippingDestinationsResponse,
    ReturnType<typeof queryKeys.shippingDestinations>
  >
) {
  async function fetchShippingDestinations() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/shipping_methods/destinations`
    )
    return response.data
  }

  return useQuery({
    queryKey: queryKeys.shippingDestinations(),
    queryFn: fetchShippingDestinations,
    ...options,
  })
}

export function useFetchShippingMethods({
  shippableProducts,
  countryCode,
  currencyCode,
  options,
}: {
  shippableProducts: TShippableProductInCart[]
  countryCode: TShippingDestination["country_code"]
  currencyCode: TCurrencyCode
  options?: Omit<
    UseQueryOptions<
      TFetchShippingMethodsResponse,
      AxiosError,
      TFetchShippingMethodsResponse,
      ReturnType<typeof queryKeys.shippingMethods>
    >,
    "queryKey" | "queryFn"
  >
}) {
  async function fetchShippingMethods() {
    const response = await minutApiHttpClient.post<
      AxiosError,
      AxiosResponse<TFetchShippingMethodsResponse>,
      TFetchShippingMethodsRequestBody
    >(`${API_DEFAULT}/ecommerce/cart_estimate/shipping_methods`, {
      shippable_products: shippableProducts,
      shipping_country: countryCode,
      currency: currencyCode,
    })
    return response.data
  }

  return useQuery({
    queryKey: queryKeys.shippingMethods(
      shippableProducts,
      countryCode,
      currencyCode
    ),
    queryFn: fetchShippingMethods,
    ...options,
  })
}

const GEO_LOCATION_STORAGE_KEY = "minut.geolocation"

export async function fetchGeoLocation() {
  const storedGeoLocation = window.sessionStorage.getItem(
    GEO_LOCATION_STORAGE_KEY
  )

  if (storedGeoLocation) {
    return JSON.parse(storedGeoLocation)
  }

  const response = await axios.get<TFetchGeolocationResponse>(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GATSBY_IP_GEOLOCATION_API_KEY}`,
    { timeout: 5000 }
  )

  const {
    country_code2: country_code,
    currency: { code: currency_code },
  } = response.data

  const geoLocation = { country_code, currency_code }

  window.sessionStorage.setItem(
    GEO_LOCATION_STORAGE_KEY,
    JSON.stringify(geoLocation)
  )

  return geoLocation
}

export function useFetchGeolocation(props?: {
  options?: Omit<
    UseQueryOptions<
      TGeolocation,
      AxiosError,
      TGeolocation,
      ReturnType<typeof queryKeys.geoLocation>
    >,
    "queryKey" | "queryFn"
  >
}) {
  return useQuery({
    queryKey: queryKeys.geoLocation(),
    queryFn: fetchGeoLocation,
    ...props?.options,
  })
}
