export const colorScale = {
  hem: {
    100: "#ECEFF2",
    200: "#E5EAEE",
    300: "#DFE5EA",
    400: "#CBD5DD",
    500: "#BECBD4",
    600: "#ABB7BF",
    700: "#5f666a",
    800: "#4c5155",
  },
  casa: {
    100: "#fcf9fa",
    200: "#F9F4F4",
    300: "#F8F1F2",
    400: "#F5EBEC",
    500: "#F0E3E4",
    600: "#c0b6b6",
    700: "#908889",
    800: "#605b5b",
  },
  rumah: {
    100: "#F9FAFA",
    200: "#F0F3F2",
    300: "#E0E8E5",
    400: "#D1DCD8",
    500: "#C1D0CB",
    600: "#919C98",
    700: "#616866",
    800: "#303433",
  },
  gaff: {
    100: "#E9ECF2",
    200: "#C8CFE0",
    300: "#929FC0",
    400: "#5B6EA1",
    500: "#243E81",
    600: "#1B2F61",
    700: "#121F41",
    800: "#091020",
  },
  hejmo: {
    100: "#e8ebed",
    200: "#d1d6da",
    300: "#a3aeb5",
    400: "#758590",
    500: "#475d6b",
    600: "#1B2F61",
    700: "#193446",
    800: "#122431",
  },
  koti: {
    100: "#F7F7F7",
    200: "#E6E6E6",
    300: "#D6D6D6",
    400: "#A4A4A4",
    500: "#8b8b8b",
    600: "#707070",
    700: "#595959",
    800: "#1F1F1F",
  },
  good: {
    100: "#EDF5EE",
    200: "#D2E6D3",
    300: "#A6CEA8",
    400: "#79B57C",
    500: "#4C9C50",
    600: "#39753C",
    700: "#264E28",
    800: "#132714",
  },
  warning: {
    100: "#FEF8EC",
    200: "#FCEDCF",
    300: "#F9DBA0",
    400: "#F6C870",
    500: "#D67D00",
    600: "#B68930",
    700: "#7A4D20",
    800: "#3D2710",
  },
  error: {
    100: "#F9EBEC",
    200: "#F1CED0",
    300: "#E29DA1",
    400: "#D46B71",
    500: "#C53A42",
    600: "#942C32",
    700: "#631D21",
    800: "#310F11",
  },
  white: "#FFFFFF",
}

export const colors = {
  primary: colorScale.gaff[500],
  primaryLight: colorScale.gaff[100],
  accentPrimary: colorScale.hem[500],
  accentPrimaryLight: colorScale.hem[100],
  accentSecondary: colorScale.casa[500],
  accentSecondaryLight: colorScale.casa[200],
  accentTertiary: colorScale.rumah[500],
  accentTertiaryLight: colorScale.rumah[200],
  neutral: colorScale.koti[100],
  neutralLight: colorScale.white,
  neutralDark: colorScale.koti[200],
  neutralDark2: colorScale.koti[300],
  neutralDark3: colorScale.koti[600],
  divider: colorScale.hem[300],
  dividerDark: colorScale.hem[400],
  textPrimary: colorScale.hejmo["700"],
  textInactive: colorScale.hejmo["500"],
  textSecondary: colorScale.koti["700"],
  textTertiary: colorScale.koti["600"],
  textContrast: colorScale.white,
  systemGood: colorScale.good["500"],
  systemGoodLight: colorScale.good["100"],
  systemGoodDark: colorScale.good["600"],
  systemWarning: colorScale.warning["500"],
  systemWarningLight: colorScale.warning["200"],
  systemWarningDark: colorScale.warning["700"],
  systemError: colorScale.error["500"],
  systemErrorLight: colorScale.error["100"],
  systemErrorDark: colorScale.error["600"],
  systemInfoLight: colorScale.gaff["100"],
  systemInfoDark: colorScale.hejmo["700"],
}

/**
 * @deprecated Use new palette and color system instead
 */
export const pink = "#F0E3E4"
export const green = "#ACD0AF"
export const yellow = "#E1CD9A"
export const purple = "#C7BDD0"
export const teal = "#8BC1BB"
export const orange = "#E0997F"
export const grey = "#BFCBD4"
export const sage = "#C1D0CB"
export const sageLight = "#E2EBE8"
export const hav = "#537AA1"
export const primary = hav

/** @deprecated use color system directly instead */
export const primaryTint = colors.primary

/** @deprecated use color system directly instead */
export const minimumGray = colors.textTertiary

/** @deprecated use color system directly instead */
export const backgroundGray = "#E3E3E3"

/** @deprecated use color system directly instead */
export const backgroundGrayV2 = "#F7F7F7"

/** @deprecated use color system directly instead */
export const backgroundGrayStore = "#FAFAFA"

/** @deprecated use color system directly instead */
export const divider = colors.divider

/** @deprecated use color system directly instead */
export const systemOkBackground = colors.systemGoodLight
/** @deprecated use color system directly instead */
export const systemOkForeground = colors.systemGoodDark

/** @deprecated use color system directly instead */
export const systemInfoBackground = colors.systemInfoLight
/** @deprecated use color system directly instead */
export const systemInfoForeground = colors.systemInfoDark

/** @deprecated use color system directly instead */
export const systemEmergencyBackground = colors.systemErrorLight
/** @deprecated use color system directly instead */
export const systemEmergencyForeground = colors.systemErrorDark

/** @deprecated use color system directly instead */
export const systemWarningBackground = colors.systemWarningLight
/** @deprecated use color system directly instead */
export const systemWarningForeground = colors.systemWarningDark
