import { TPostTokenResponse } from "src/domains/auth/auth-types"
import { getJwtFromAccessToken } from "src/domains/auth/auth-utils"

// Authorization
const authorizationKey = "authorization"

export function setStoredAuthorization(data: TPostTokenResponse): void {
  window.localStorage.setItem(authorizationKey, JSON.stringify(data))
}

export function getStoredAuthorization(): TPostTokenResponse | null {
  if (typeof window === "undefined") return null
  const auth = window.localStorage.getItem(authorizationKey)
  return auth ? JSON.parse(auth) : null
}

export function getStoredRefreshToken(): string | undefined {
  return getStoredAuthorization()?.refresh_token
}

// Impersonation
const impersonateKey = "impersonate"

export function setStoredImpersonation(a: TPostTokenResponse) {
  if (!a.access_token) {
    console.error("Stored auth is missing access_token, clearing auth storage")
    clearAuthStorage()
    return
  }

  const jwtData = getJwtFromAccessToken(a.access_token)
  setStoredAuthorization(a)
  window.localStorage.setItem(
    impersonateKey,
    JSON.stringify({ user_id: a.user_id, expires_at: jwtData.exp })
  )
}

export function getStoredImpersonation() {
  if (typeof window === "undefined") return null
  const impersonation = window.localStorage.getItem(impersonateKey)
  return impersonation ? JSON.parse(impersonation) : null
}

export function getImpersonateActive() {
  return !!getStoredImpersonation()?.user_id
}

/**
 * Clears all auth related data from local storage and removes auth query
 * param if it exists
 */
export function clearAuthStorage() {
  window.localStorage.removeItem(authorizationKey)
  window.localStorage.removeItem(impersonateKey)
  // TODO: remove auth query here?
}
