type TAcceptedTypes = "string" | "number" | "boolean"

export type TQueryKey = {
  key: string
  type: TAcceptedTypes
}

type TMapSearchParamsByKeys<QueryKeys extends TQueryKey[]> = {
  [key in QueryKeys[number] as key["key"]]: NameToPrimitiveType<
    key["type"]
  > | null
}

export type NameToPrimitiveType<T extends string> = T extends "string"
  ? string
  : T extends "number"
  ? number
  : T extends "boolean"
  ? boolean
  : undefined

/**
 * Retrieves and converts a query parameter from the URL's search parameters.
 *
 * @param {string} params.key - The key of the query parameter to retrieve.
 * @param {string} params.type - The expected primitive type of the query parameter.
 * @returns {NameToPrimitiveType<QueryKey["type"]> | null} The converted query parameter value or null if not found.
 *
 * @example
 * // For a URL like "https://example.com?userId=123"
 * const userId = getQueryParam({ key: "userId", type: "number" });
 * // Returns: 123 (as a number) or null if not found
 */
export function getQueryParam<const QueryKey extends TQueryKey>({
  key,
  type,
}: QueryKey): NameToPrimitiveType<QueryKey["type"]> | null {
  if (typeof window === "undefined") {
    return null
  }

  const searchParams = new URLSearchParams(window.location.search)

  const searchParam = searchParams.get(key)

  return convertStringToPrimitiveType(type, searchParam) as NameToPrimitiveType<
    QueryKey["type"]
  > | null
}

/**
 * Extracts and converts query parameters from the URL search string based on provided keys configuration
 *
 * @param keys - Array of query parameter configurations, each containing a key and expected type
 * @returns Object with query parameter values converted to their specified types
 *
 * @example
 * ```typescript
 * const params = getQueryParams([
 *   { key: 'id', type: 'number' },
 *   { key: 'name', type: 'string' }
 * ]);
 * // For URL "?id=123&name=test"
 * // Returns: { id: 123, name: "test" }
 * ```
 */
export function getQueryParams<const QueryKeys extends TQueryKey[]>(
  keys: QueryKeys
): TMapSearchParamsByKeys<QueryKeys> {
  if (typeof window === "undefined") {
    return Object.fromEntries(
      keys.map((conf) => [conf.key, null])
    ) as TMapSearchParamsByKeys<QueryKeys>
  }

  const searchParams = new URLSearchParams(window.location.search)

  const searchParamsObjectEntries = Object.values(keys).map((conf) => {
    const searchParam = searchParams.get(conf.key)

    return [conf.key, convertStringToPrimitiveType(conf.type, searchParam)]
  })

  return Object.fromEntries(searchParamsObjectEntries)
}

function convertStringToPrimitiveType(
  type: TAcceptedTypes,
  value: string | null
) {
  if (value === null) {
    return null
  }

  if (type === "number") {
    const parsedValue = Number(value)

    return !isNaN(parsedValue) ? parsedValue : null
  }

  if (type === "boolean") {
    return value.toLowerCase() === "true"
  }

  return value
}
